<template>
  <div>
    <!-- <Card class="card">
      <p>消息中心</p>
    </Card> -->
    <div class="info">
      <p>消息中心</p>
    </div>
    <div class="choice">
      <el-badge :value="a" class="item">
        <p class="active" ref="lie1" @click="lie(1)">消息列表</p>
      </el-badge>
      <el-badge :value="b" class="item">
        <p ref="lie2" @click="lie(2)">红娘来信</p>
      </el-badge>
    </div>

    <!-- 消息框 -->
    <div class="messages">
      <!-- 消息列表 -->

      <div v-show="lieB">
        <!-- 会员 -->
        <div v-if="isVipMember == 1">
          <div class="messages-li" v-if="!xiao">
            <ul class="messages-li">
              <!-- 也在助手 -->
              <li v-if="hyzs">
                <!-- 头像 -->
                <div class="touxi">
                  <div class="xixi" @click="ziliao(item.id)">
                    <img :src="zsTx" alt="" />
                  </div>
                </div>
                <div class="mess-box">
                  <div class="mess-name">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        vertical-align: middle;
                      "
                      class="mess-name-n"
                    >
                      <p style="margin-right: 10px; font-weight: bold">
                        也在小助手
                      </p>
                      <p class="mess-name-n-m">官方</p>
                      <!-- <p class="mess-name-n-o">实民认证</p> -->
                    </div>
                  </div>

                  <p class="qianmin" style="">
                    {{ hyzs.payload }}
                  </p>

                  <p></p>

                  <!-- 时间 -->
                  <p
                    class="time"
                    v-if="
                      new Date(hyzs.timestamp).getDate() ==
                        new Date().getDate() &&
                        new Date(hyzs.timestamp).getMonth() ==
                          new Date().getMonth() &&
                        new Date(hyzs.timestamp).getYear() ==
                          new Date().getYear()
                    "
                  >
                    {{ $moment(hyzs.timestamp).format("H:mm ") }}
                  </p>
                  <p
                    class="time"
                    v-else-if="
                      new Date(hyzs.timestamp).getDate() ==
                        new Date().getDate() - 1 &&
                        new Date(hyzs.timestamp).getMonth() ==
                          new Date().getMonth() &&
                        new Date(hyzs.timestamp).getYear() ==
                          new Date().getYear()
                    "
                  >
                    <!-- {{ $moment(item.timestamp).subtract(1, "days").calendar() }} -->
                    {{ $moment(hyzs.timestamp).calendar() }}
                  </p>
                  <p class="time" v-else>
                    {{ $moment(hyzs.timestamp).format("MMM Do") }}
                  </p>
                </div>
                <div class="weidu">
                  <p>
                    {{ hyzs.unread_num > 99 ? "99+" : hyzs.unread_num }}封未读
                  </p>
                </div>
                <div class="Btn-box">
                  <div class="Btn" @click="chakan(10000)">点击查看</div>
                </div>
                <!-- </div> -->
              </li>

              <li v-for="item in HYUU" :key="item.id">
                <!-- 头像 -->
                <div class="touxi">
                  <div class="xixi" @click="ziliao(item.id)">
                    <img :src="item.avatarurl" alt="" />
                  </div>
                </div>
                <div class="mess-box">
                  <div class="mess-name">
                    <!-- <p>{{ item.nickName }} <i>图标</i></p> -->
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        vertical-align: middle;
                      "
                      class="mess-name-n"
                    >
                      <p style="margin-right: 10px; font-weight: bold">
                        {{ item.nickname }}
                      </p>
                      <img
                        :src="item.vipmember == 0 ? `` : `${MemberIcon}`"
                        alt=""
                        style="margin-right: 5px"
                      />
                      <p class="mess-name-n-m" v-if="item.realauth == 2">
                        人脸认证
                      </p>
                      <p class="mess-name-n-o" v-if="item.realauth == 2">
                        实民认证
                      </p>
                    </div>
                  </div>
                  <!-- item.introduce.substring(0, 20) +
                    (item.introduce.length > 19 ? "..." : "") -->
                  <p class="qianmin" style="">
                    <!-- {{ item.mes }} -->
                    {{
                      item.mes.substring(0, 20) +
                        (item.mes.length > 19 ? "..." : "")
                    }}
                  </p>
                  <!-- 时间 -->
                  <p
                    class="time"
                    v-if="
                      new Date(item.timestamp).getDate() ==
                        new Date().getDate() &&
                        new Date(item.timestamp).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.timestamp).getYear() ==
                          new Date().getYear()
                    "
                  >
                    {{ $moment(item.timestamp).format("H:mm ") }}
                  </p>
                  <p
                    class="time"
                    v-else-if="
                      new Date(item.timestamp).getDate() ==
                        new Date().getDate() - 1 &&
                        new Date(item.timestamp).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.timestamp).getYear() ==
                          new Date().getYear()
                    "
                  >
                    <!-- {{ $moment(item.timestamp).subtract(1, "days").calendar() }} -->
                    {{ $moment(item.timestamp).calendar() }}
                  </p>
                  <p class="time" v-else>
                    {{ $moment(item.timestamp).format("MMM Do") }}
                  </p>
                </div>

                <div class="weidu" v-if="item.unread_num !== 0">
                  <p>{{ item.unread_num }}封未读</p>
                </div>
                <div class="weidu_a" v-else>
                  <p>全部读完</p>
                </div>
                <div class="Btn-box_x" v-if="item.unread_num == 0">
                  <div class="Btn" @click="look(item.id)">点击查看</div>
                </div>
                <div class="Btn-box" v-else>
                  <div class="Btn" @click="look(item.id)">点击查看</div>
                </div>
              </li>
            </ul>
            <!-- <div class="low">
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page="currentPage1"
                :hide-on-single-page="valueP"
                background
                layout="prev, pager, next"
                :total="totalP"
              >
              </el-pagination>
            </div> -->
          </div>
        </div>
        <!-- 非会员 -->
        <div v-else>
          <ul class="messages-li" v-if="!xiao">
            <li class="tq">
              <div class="touxi">
                <div class="xixi">
                  <img
                    src="../../../../assets/images/index/huiyuan.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="mess-box">
                <div class="mess-name">
                  <p>快速找对象</p>
                </div>
                <p class="qianmin" style="">
                  无限制看信发信各项特权祝你邂逅真爱
                </p>
              </div>
              <div class="Btn-tq">
                <div class="Btn" @click="chakan(10001)">点击查看</div>
              </div>
            </li>
            <!-- 也在助手 -->
            <li v-if="hyzs">
              <!-- 头像 -->
              <div class="touxi">
                <div class="xixi" @click="ziliao(item.id)">
                  <img :src="zsTx" alt="" />
                </div>
              </div>

              <div class="mess-box">
                <div class="mess-name">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      vertical-align: middle;
                    "
                    class="mess-name-n"
                  >
                    <p style="margin-right: 10px; font-weight: bold">
                      也在小助手
                    </p>
                    <p class="mess-name-n-m">官方</p>
                    <!-- <p class="mess-name-n-o">实民认证</p> -->
                  </div>
                </div>

                <p class="qianmin" style="">
                  <!-- {{ hyzs.payload }} -->
                  {{
                    hyzs.payload.substring(0, 20) +
                      (hyzs.payload.length > 19 ? "..." : "")
                  }}
                </p>

                <!-- <p>{{ hyzs }}</p> -->

                <!-- 时间 -->
                <p
                  class="time"
                  v-if="
                    new Date(hyzs.timestamp).getDate() ==
                      new Date().getDate() &&
                      new Date(hyzs.timestamp).getMonth() ==
                        new Date().getMonth() &&
                      new Date(hyzs.timestamp).getYear() == new Date().getYear()
                  "
                >
                  {{ $moment(hyzs.timestamp).format("H:mm ") }}
                </p>
                <p
                  class="time"
                  v-else-if="
                    new Date(hyzs.timestamp).getDate() ==
                      new Date().getDate() - 1 &&
                      new Date(hyzs.timestamp).getMonth() ==
                        new Date().getMonth() &&
                      new Date(hyzs.timestamp).getYear() == new Date().getYear()
                  "
                >
                  <!-- {{ $moment(item.timestamp).subtract(1, "days").calendar() }} -->
                  {{ $moment(hyzs.timestamp).calendar() }}
                </p>
                <p class="time" v-else>
                  {{ $moment(hyzs.timestamp).format("MMM Do") }}
                </p>
              </div>
              <div class="weidu" v-if="hyzs.unread_num !== 0">
                <p>{{ hyzs.unread_num }}封未读</p>
              </div>
              <div class="weidu_a" v-else>
                <p>全部读完</p>
              </div>
              <!-- <div class="weidu">
                <p>{{ hyzs.unread_num }}封未读</p>
              </div> -->
              <div class="Btn-box_x" v-if="hyzs.unread_num == 0">
                <div class="Btn">点击查看</div>
              </div>
              <div class="Btn-box" v-else>
                <div class="Btn">点击查看</div>
              </div>
              <!-- </div> -->
            </li>
            <li v-for="item in HYUU" :key="item.id">
              <!-- 头像 -->
              <div class="touxi">
                <div class="xixi">
                  <img :src="item.avatarurl" alt="" @click="ziliao(item.id)" />
                </div>
              </div>
              <div class="mess-box">
                <div class="mess-name">
                  <!-- <p>{{ item.nickName }} <i>图标</i></p> -->
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      vertical-align: middle;
                    "
                    class="mess-name-n"
                  >
                    <p style="margin-right: 10px; font-weight: bold">
                      {{ item.nickname }}
                    </p>
                    <img
                      :src="item.vipmember == 0 ? `` : `${MemberIcon}`"
                      alt=""
                      style="margin-right: 5px"
                    />
                    <p class="mess-name-n-m" v-if="item.realauth == 2">
                      人脸认证
                    </p>
                    <p class="mess-name-n-o" v-if="item.realauth == 2">
                      实名认证
                    </p>
                  </div>
                </div>
                <div class="qianmin-n">
                  <span v-if="item.age">{{ item.age }}岁&nbsp;</span>
                  <span v-if="item.height"
                    >&nbsp;|&nbsp;{{ item.height }}cm&nbsp;</span
                  >
                  <span v-if="item.monthIncome"
                    >&nbsp;|&nbsp;{{
                      item.monthIncome == 0
                        ? "3000元以下"
                        : item.monthIncome == 1
                        ? "3001-5000元"
                        : item.monthIncome == 2
                        ? "5001-8000元"
                        : item.monthIncome == 3
                        ? "8001-12000元"
                        : item.monthIncome == 4
                        ? "12001-20000元"
                        : item.monthIncome == 5
                        ? "20000-50000元"
                        : item.monthIncome == 6
                        ? "50000元以上"
                        : ""
                    }}&nbsp;
                  </span>
                  <span v-if="item.workAddrCityName"
                    >&nbsp;|&nbsp;{{ item.workAddrCityName }}
                  </span>
                </div>
                <p
                  class="time"
                  v-if="
                    new Date(item.timestamp).getDate() ==
                      new Date().getDate() &&
                      new Date(item.timestamp).getMonth() ==
                        new Date().getMonth() &&
                      new Date(item.timestamp).getYear() == new Date().getYear()
                  "
                >
                  {{ $moment(item.timestamp).format("H:mm ") }}
                </p>
                <p
                  class="time"
                  v-else-if="
                    new Date(item.timestamp).getDate() ==
                      new Date().getDate() - 1 &&
                      new Date(item.timestamp).getMonth() ==
                        new Date().getMonth() &&
                      new Date(item.timestamp).getYear() == new Date().getYear()
                  "
                >
                  <!-- {{ $moment(item.timestamp).subtract(1, "days").calendar() }} -->
                  {{ $moment(item.timestamp).calendar() }}
                </p>
                <p class="time" v-else>
                  {{ $moment(item.timestamp).format("MMM Do") }}
                </p>
              </div>
              <div class="weidu" v-if="item.unread_num !== 0">
                <p>{{ item.unread_num }}封未读</p>
              </div>
              <div class="weidu_a" v-else>
                <p>全部读完</p>
              </div>

              <div class="Btn-box_x" v-if="item.unread_num == 0">
                <div class="Btn" @click="chakan(10002)">点击查看</div>
              </div>
              <div class="Btn-box" v-else>
                <div class="Btn" @click="chakan(10002)">点击查看</div>
              </div>
              <!-- <el-button type="text" @click="open(item)"
                ><i class="el-icon-close close"> </i
              ></el-button> -->
            </li>

            <!-- <div class="low"> -->
            <!-- <el-pagination
                @current-change="handleCurrentChange"
                :current-page="currentPage1"
                :hide-on-single-page="valueP"
                background
                layout="prev, pager, next"
                :total="totalP"
              >
              </el-pagination> -->
            <!-- </div> -->
          </ul>
        </div>
        <!-- 数据为空 -->
        <div class="kong" v-if="xiao">
          <img src="../../../../assets/images/message/girl.png" alt="" />
          <p>
            用行动来证明你的态度，用力争取属于你的幸福<br />
            动起来，别再让爱等待！
          </p>
          <div class="btn-box" @click="chuji">主动出击</div>
        </div>
      </div>

      <!-- 红娘来信 -->
      <div class="messages-li" v-show="lieA">
        <!-- 红娘来信 -->
        <div class="kong" v-show="true">
          <img src="../../../../assets/images/message/girl.png" alt="" />
          <p>
            服务流程五步走，六重保障觅真爱 <br />
            相亲无难事，也在有红娘！
          </p>
          <div class="btn-box" @click="ServiceStanding">了解也在红娘服务</div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <div class="iSmo" v-show="iSmo">
      <div class="iSmo_app">
        <!-- 头部 -->
        <div class="iSmo_app_title">
          <p>下载APP查看消息</p>
          <p class="el-icon-close" @click="close"></p>
        </div>
        <div class="iSmo_app_content">
          <img src="../../../../assets/images/logo/logo.png" alt="" />
          <p class="iSmo_app_content_text">扫描二维码,下载APP查看消息</p>
        </div>
        <div class="iSmo_app_btn" @click="close">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
// import Card from "@/components/Card";
import local from "@/api/common/local.js";
import { messagePage, infocur } from "@/api/login/login.js"; //
import { ossL } from "@/api/common/oss/ossL.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      a: "",
      b: "",
      value: false,
      lieA: false,
      lieB: true,
      xiao: true,
      hong: false,
      total: 10,
      tableData: [],
      pageCurrent: 1,
      isVipMember: "",
      MemberIcon: require("@/assets/images/tubiao/yezai_member.png"),
      realAuthStatusIcon: require("@/assets/images/tubiao/yezai_auth.png"),
      currentPage1: 1,
      pageSize: 10,
      totalP: null,
      valueP: false,
      HYmess: [],
      HYuser: [],
      HYuserinfo: [],
      HYUU: [],
      hyzs: null,
      zsTx: require("@/assets/images/tubiao/yzzs.png"),
      idCard: "",
      iSmo: false, //弹框
      UserinfoL: "",
    };
  },
  created() {
    this.messageAdd();
  },
  methods: {
    ...mapMutations(["mess"]),
    //登录环信账号
    login() {
      const _that = this;
      var options = {
        // user: this.idCard, // ----------------------------------------------------------------------------------(当前用户账号)
        // accessToken: token,
        // appKey: this.WebIM.config.appkey,
        // success: function (res) {
        //   console.log("00", res);
        // },
        // fail: function () {},
        user: _that.idCard,
        pwd: "yezai_user@easemob",
        // appKey: _that.WebIM.config.appkey,
        appKey: _that.$WebIM.config.appkey,
        success: function() {
          console.log("登录成功");
        },
        error: function() {},
      };
      // _that.WebIM.conn.open(options);
      _that.$WebIM.conn.open(options);

      // _that.WebIM.conn.listen({
      _that.$WebIM.conn.listen({
        onOpened: function(message) {
          console.log("00", message);
          _that.liao();
        },
        onTextMessage: function(message) {
          console.log("onTextMessage", message);
          _that.liao();
        },
      });
      //
    },
    liao() {
      // /**
      //  * @param {String|Array} users - 用户id
      //  */
      this.HYmess = [];
      this.HYuser = [];
      this.HYUU = [];
      this.$WebIM.conn.getSessionList().then((res) => {
        const data = res.data.channel_infos;
        //遍历处理数据
        data.map((i) => {
          // 也在小助手
          if (
            Number(
              i.channel_id.substring(
                i.channel_id.indexOf("_") + 1,
                i.channel_id.indexOf("@")
              )
            ) === 10000
          ) {
            this.hyzs = {
              channel_id: i.channel_id.substring(
                i.channel_id.indexOf("_") + 1,
                i.channel_id.indexOf("@")
              ),
              payload: JSON.parse(i.meta.payload).bodies[0].msg,
              timestamp: i.meta.timestamp,
              unread_num: i.unread_num,
            };
          } else if (
            i.channel_id.substring(
              i.channel_id.indexOf("_") + 1,
              i.channel_id.indexOf("@")
            ) == ""
          ) {
            console.log("001");
          } else if (
            i.channel_id.substring(
              i.channel_id.indexOf("_") + 1,
              i.channel_id.indexOf("@")
            ) == 11000
          ) {
            console.log("001");
          } else {
            this.HYmess.push({
              channel_id: i.channel_id.substring(
                i.channel_id.indexOf("_") + 1,
                i.channel_id.indexOf("@")
              ),
              payload: JSON.parse(i.meta.payload).bodies[0].msg,
              timestamp: i.meta.timestamp,
              unread_num: i.unread_num,
            });
          }
        });
        this.HYmess.map((i) => {
          this.HYuser.push(i.channel_id);
        });
        // 消息共计
        let s = 0;
        for (let i = 0; i < this.HYmess.length; i++) {
          console.log("消息", this.HYmess[i].unread_num);
          s += Number(this.HYmess[i].unread_num);
        }
        this.mess(s);
        this.$WebIM.conn.fetchUserInfoById(this.HYuser).then((res) => {
          this.HYmess.map((i) => {
            this.HYUU.push({
              nickname: res.data[i.channel_id].nickname,
              avatarurl: ossL(res.data[i.channel_id].avatarurl),

              mes: i.payload,
              id: i.channel_id,
              vipmember:
                JSON.parse(res.data[i.channel_id].ext).vipmember || "0",
              realauth: JSON.parse(res.data[i.channel_id].ext).realauth || "",
              timestamp: i.timestamp,
              unread_num: i.unread_num,
              monthIncome:
                JSON.parse(res.data[i.channel_id].ext).monthIncome || "",
              workAddrCityName:
                JSON.parse(res.data[i.channel_id].ext).workAddrCityName || "",
              age: JSON.parse(res.data[i.channel_id].ext).age || "",
              height: JSON.parse(res.data[i.channel_id].ext).height || "",
            });
            // }
            this.HYUU = this.HYUU.sort(this.compare("timestamp")); //根据时间排序(时间最近的放在最前)
            if (this.HYUU.length == 0) {
              // this.xiao = false;
              this.xiao = true;
            } else {
              // this.xiao = true;
              this.xiao = false;
            }
          });
        });
      });
    },
    //处理时间排序
    compare(property) {
      return function(a, b) {
        return b[property] - a[property]; //大到小
      };
    },
    //初始化页面
    async messageAdd() {
      let token = local.get("access_token");
      const D = await infocur(token);
      //  const _that = this;
      if (D.code == 0) {
        this.idCard = D.data.user.id;
        this.login();
        this.isVipMember = D.data.user.isVipMember; //会员为1
        let a = {
          msgTypes: "1,2,3",
          pageNum: this.currentPage1,
          pageSize: this.pageSize,
          memberId: D.data.user.memberId,
          viewType: 1,
        };
        const { code, data } = await messagePage(a);
        if (code == 0) {
          console.log("数据", data);
          // this.tableData = data.records;
          console.log("00", this.tableData);
          this.tableData = [];
          data.records.map((item) => {
            this.tableData.push(
              Object.assign({}, item, {
                Img: ossL(item.avatar)
              })
            );
          });
          this.totalP = data.total;
          //分页显示和隐藏
          if (this.totalP <= 10) {
            this.valueP = true;
          } else {
            this.valueP = false;
          }
          //
          if (data.total == 0) {
            this.xiao = true;
          } else {
            this.xiao = false;
          }
        }

        //红娘消息
        let b = {
          msgTypes: 4,
          pageNum: 1,
          pageSize: this.pageSize,
          memberId: D.data.user.memberId,
          viewType: 1,
        };
        const Dt = await messagePage(b);
        if (Dt.code == 0) {
          console.log("数据", Dt.data);
          if (Dt.data.total == 0) {
            this.hong = true;
          } else {
            this.hong = false;
          }
        }
      }
    },
    //切换正常消息和红娘消息
    lie(num) {
      if (num == 1) {
        this.$refs.lie1.classList.add("active");
        this.$refs.lie2.classList.remove("active");
        this.lieB = true;
        this.lieA = false;
      } else {
        this.$refs.lie1.classList.remove("active");
        this.$refs.lie2.classList.add("active");
        this.lieB = false;
        this.lieA = true;
      }
    },
    //跳转页面
    ServiceStanding() {
      let rel = this.$router.resolve({
        path: "/directStore/home",
      });
      window.open(rel.href, "_blank");
    },
    jump(item) {
      let rel = this.$router.resolve({
        path: "/u",
        query: {
          id: item,
        },
      });
      window.open(rel.href, "_blank");
    },
    //页面为空跳转(消息页为空)
    chuji() {
      let rel = this.$router.resolve({
        path: "/n/myyezai",
      });
      window.open(rel.href, "_blank");
    },
    //点击查看
    look(item) {
      //消息数清空
      let msg = new this.$WebIM.message(
        "channel",
        this.$WebIM.conn.getUniqueId()
      );
      msg.set({
        to: `${item}`,
      });
      this.$WebIM.conn.send(msg.body);
      let rel = this.$router.resolve({
        path: "/chat",
        query: {
          objectID: item,
        },
      });
      window.open(rel.href, "_blank");
    },
    //非会员点击查看
    chakan(id) {
      if (id == 10000) {
        this.iSmo = !this.iSmo;
      } else if (id == 10001) {
        // this.iSmo = !this.iSmo;
        let rel = this.$router.resolve({
          path: "/n/yezaiMember",
        });
        window.open(rel.href, "_blank");
      } else if (id == 10002) {
        let rel = this.$router.resolve({
          path: "/n/yezaiMember",
        });
        window.open(rel.href, "_blank");
      }
    },
    close() {
      this.iSmo = !this.iSmo;
    },
    // 分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      // this.cs();
      this.currentPage1 = val;
    },
    //资料
    ziliao(item) {
      console.log("资料", item);
      let rel = this.$router.resolve({
        path: "/u",
        query: {
          id: item,
        },
      });
      window.open(rel.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.low {
  /deep/ .el-pagination {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
//分页样式修改
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fd686eff !important;
  color: #fff;
  border: none !important;
}
/deep/ .el-badge__content.is-fixed {
  right: 28px;
}
.item {
  margin-top: 10px;
  margin-right: 40px;
}
//组件card
// .card {
//   border-bottom: 1px solid #f4f4f4ff;

//   /deep/ .info {
//     margin-left: 32px;
//     p:nth-child(2) {
//       display: none;
//     }
//   }
// }
// card
.info {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  position: relative;
  height: 32px;
  border-bottom: 1px solid #f4f4f4ff;
  padding-left: 32px;
  p {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
  }
  p::before {
    content: "";
    width: 4px;
    height: 16px;
    background: #fd686e;
    position: absolute;
    top: 23px;
    left: 20px;
  }
}
.choice {
  display: flex;
  padding-top: 15px;
  padding-left: 24px;
  p {
    cursor: pointer;
    margin-right: 30px;
    height: 32px;
  }
  .active {
    font-size: 14px;
    font-weight: 400;
    color: #fd686e;
    border-bottom: 2px solid #fd686e;
  }
}
.messages {
  .messages-li {
    li:nth-child(1) {
      margin-top: 8px;
    }
    li {
      display: flex;
      padding-top: 24px;
      box-sizing: border-box;
      position: relative;
      border-bottom: 1px solid #ececec;
      padding-bottom: 14px;
      .touxi {
        width: 80px;
        height: 80px;
        margin-right: 10px;
        box-sizing: border-box;
        margin-left: 11px;
        .xixi {
          width: 80px;
          height: 80px;
          img {
            width: 80px;
            height: 80px;
          }
        }
      }
      .mess-box {
        width: 555px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .mess-name {
          margin-bottom: 12px;
          p {
            font-size: 16px;
            color: #333;
          }
          .mess-name-n {
            .mess-name-n-m {
              padding: 2px 6px;
              margin-right: 6px;
              background-color: #dfd6fe;
              color: #9579fe;
              font-size: 10px;
              border-radius: 2px;
            }
            .mess-name-n-o {
              padding: 2px 6px;

              background-color: #c5f3ee;
              color: #40d9c7;
              font-size: 10px;
              border-radius: 2px;
            }
          }
        }
        .qianmin {
          width: 462px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          margin-bottom: 5px;
          color: #777777;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .qianmin-n {
          span {
            font-size: 14px;
            color: #666;
          }
        }
      }
      .weidu {
        display: flex;
        align-items: center;
        // margin-right: 12px;
        margin-right: 24px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #777777;
        }
      }
      .weidu_a {
        display: flex;
        align-items: center;
        // margin-right: 12px;
        margin-right: 24px;
        // width: px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #777777;
        }
      }
      .Btn-box {
        display: flex;
        justify-content: center;
        align-items: center;
        .Btn {
          width: 100px;
          height: 36px;
          background-color: #fd686e;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
        }
      }
      .Btn-box_x {
        display: flex;
        justify-content: center;
        align-items: center;
        .Btn {
          width: 100px;
          height: 36px;
          // background-color: #;
          background: #dbdbdb;
          // color: #ffffff;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
        }
      }
      .time {
        color: #adadad;
      }
    }
    // 非会员第一列
    .tq {
      display: flex;
      padding-top: 24px;
      box-sizing: border-box;
      position: relative;
      border-bottom: 1px solid #ececec;
      padding-bottom: 14px;
      .touxi {
        width: 80px;
        height: 80px;
        margin-right: 10px;
        box-sizing: border-box;
        margin-left: 11px;
        .xixi {
          width: 80px;
          height: 80px;
          img {
            width: 80px;
            height: 80px;
          }
        }
      }
      .mess-box {
        width: 555px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .mess-name {
          margin-bottom: 12px;
          p {
            font-size: 16px;
          }
        }
        .qianmin {
          width: 462px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #777777;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .time {
          color: #adadad;
        }
      }
      .Btn-tq {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 72px;
        .Btn {
          width: 100px;
          height: 36px;
          background-color: #fd686e;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
        }
      }
    }
    li:hover {
      background-color: #fde9eaff;
      cursor: pointer;
    }
    .close {
      display: none;
      position: absolute;
      top: 16px;
      right: 20px;
      color: #adadadff;
      font-weight: bold;
    }
    li:hover .close {
      display: inline-block;
    }
    //数据位空
    .kong {
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        margin-top: 40px;
        text-align: center;
        line-height: 20px;
      }
      .btn-box {
        margin-top: 60px;
        width: 222px;
        height: 40px;
        background: #fd686e;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .kong {
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin-top: 40px;
      text-align: center;
      line-height: 20px;
    }
    .btn-box {
      margin-top: 60px;
      width: 222px;
      height: 40px;
      background: #fd686e;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
//app下载二维码
.iSmo {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // z-index: 9999;
  .iSmo_app {
    position: fixed;
    top: 256px;
    left: 40%;
    background: #fff;
    width: 424px;
    // height: 230px;
    .iSmo_app_title {
      width: 424px;
      height: 44px;
      background: linear-gradient(90deg, #ffa1a7 0%, #fd686e 100%);
      line-height: 44px;
      font-size: 14px;
      color: #ffffff;
      display: flex;
      position: relative;
      :first-child {
        width: 424px;
        text-align: center;
      }
      /deep/.el-icon-close {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 12px;
      }
    }
    .iSmo_app_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 26px;
      img {
        width: 120px;
        height: 120px;
        margin-bottom: 12px;
      }
      .iSmo_app_content_text {
        font-size: 14px;
      }
    }
    .iSmo_app_btn {
      width: 180px;
      height: 40px;
      background: linear-gradient(90deg, #ffa1a7 0%, #fd686e 100%);
      margin: 0 auto;
      text-align: center;
      cursor: pointer;
      line-height: 40px;
      font-size: 14px;
      color: #fff;
      border-radius: 4px;
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }
}
</style>
